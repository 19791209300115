:root {
  --blue: rgb(56, 80, 116);
}


#root {
  background-color: var(--blue);
}

.siteImage {
  width: 160px;
  display: flex;
  flex-direction: row;
  left: 0px;
  margin-left: 10px;
}

.siteImage2 {
  width: 10px;
}

#navBar{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(./media/marbleBG.webp);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0;
  padding-bottom: 10px;
  box-shadow: 0px 1px 8px var(--blue);
}

.navBarItems {
  margin-left: 30px;
}

.linkText {
  color: var(--blue);

  
}

.linkText:hover {
 font-weight: bolder;
}

.PageContainer {
  background-color: rgb(238, 238, 238);
  width: 90%;

  margin: auto;
  padding-top: 150px;
  padding-left: 50px;
  padding-right: 50px;
  
  box-shadow: 0px 10px 100px rgb(238, 238, 238);
  
}
.teamPage {
  text-align: center;
}


.teamPage p {
  width: 80%;
  text-align:justify;
  
}

h1 {
  color: var(--blue);
}

ul li {
  width: 90%;
  text-align: left;
}
.teamPage img {
  width: 250px;
}

table tr td b {
  color: var(--blue);
}

.footer {
  margin-top: 30px;
  width: 100%;
  border-top: 1px solid black;
  padding-top: 40px;
  background-color: rgb(238, 238, 238);
  
}

.footer img {
  width: 200px;
  left: 0;
}

.footerLinks {
  color: var(--blue);
  text-decoration: none;
  font-size: 20px;
}


.button {
  background-color: var(--blue);
  width: 200px;
  display: flex;
  flex-direction: column;
  color: white;
  
}

.modalStyle {
  width: max-content;
}

.modalStyle, h3, label {
  color: var(--blue);

}

.modalStyle input {
  display: flex;
  flex-direction: row;
  background: rgb(254, 254, 254);
}

h2 {
  text-align: left;
}

.tableStyles {
  line-height: px;
}

.tableStyles th {
  color: rgb(0, 0, 0);
  border-bottom: solid 1px black;
}

th, td {
  padding: 20px;
}

img {
  width: 300px;
}

.testimonial {
  color: rgb(72, 72, 72);
  text-align: center;
  border-top: .1px dotted black;
  border-bottom: .1px dotted black;
  padding: 35px;
  
  
}

span {
  color: var(--blue);
}

.contactUs {
  height: 700px;

}


#freeform {
  background-color: rgb(238, 238, 238);
  width: 100%;
  height: 125px;
}

